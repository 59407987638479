import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

// data is injected into the template by the graphql query below
export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout
      title={frontmatter.title}
      written={<div>First Written: {frontmatter.written}</div>}
      updated={<div>Last Updated: {frontmatter.edited}</div>}>
      <div className="blog-post-container">
        <div className="blog-post">
          {frontmatter.note
            ? <center>
                <br/>
                <i>{frontmatter.note}</i>
                <br/><br/>
                <center>_____________</center>
                <br/><br/><br/>
              </center>
            : null
          }
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        written(formatString: "MMMM DD, YYYY")
        edited(formatString: "MMMM DD, YYYY")
        note
      }
    }
  }
`
